import '../css/index.css'
import screenImg from './qingboji.jpg'
import appicon from './appicon.png'
import { Helmet } from 'react-helmet-async';

function index() {
    return (
        <div></div>
    )
}

export default index;